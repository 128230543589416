<template>
    <form class="form registration">
        <div class="registration__title">Free registration</div>

        <Input
            type="text"
            placeholder="Name"
            :value="form.name"
            :error="v$.form.name.$error"
            :error-text="v$.form.name.$error && v$.form.name.$errors[0].$message || ''"
            @eventInput="(v$.form.name.$model = $event, delayTouch(v$.form.name))"
        />
        <Input
            type="email"
            placeholder="Email"
            :value="form.email"
            :error="v$.form.email.$error"
            :error-text="v$.form.email.$error && v$.form.email.$errors[0].$message || ''"
            @eventInput="(v$.form.email.$model = $event, delayTouch(v$.form.email))"
        />
        <div v-if="error" class="form__error">{{ error }}</div>
        <Checkbox
            placeholder="I’m not a robot"
            v-model="form.sign_agree"
            :error="v$.form.sign_agree.$error"
            @update:value="v$.form.sign_agree.$model = $event"
        />
        <Button
            class="button"
            text="JOIN US"
            :disabled="isDisabled()"
            @eventClick="submit"
        />
    </form>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, sameAs, minLength, maxLength } from '@vuelidate/validators';
import Input from '@/components/Base/Input';
import Checkbox from '@/components/Base/Checkbox';
import Button from '@/components/Base/Button';

export default {
    name: 'Registration',
    props: {
        className: {
            type: String,
            default: '',
        }
    },
    components: {
        Input,
        Checkbox,
        Button
    },
    setup: () => ({
        v$: useVuelidate()
    }),
    data: () => ({
        form: {
            name: null,
            email: null,
            sign_agree: false,
        },
        errors: {},
        wMap: new WeakMap(),
    }),
    validations: () => ({
        form: {
            name: { required, minLength: minLength(3), maxLength: maxLength(15) },
            email: {
                required,
                email
            },
            sign_agree: { sameAs: sameAs(true) }
        }
    }),
    created() {
        this.getParams();
    },
    methods: {
        delayTouch(v) {
            this.errors[v.$path] = v.$error
            v.$reset();
            if (this.wMap.has(v)) clearTimeout(this.wMap.get(v));
            this.wMap.set(v, setTimeout(v.$touch, 1000));
        },
        async getIP() {
            try {
                const response = await fetch('https://api.ipify.org/?format=json');
                const { ip } = await response.json();

                return ip;
            } catch (error) {
                return null;
            }
        },
        async getParams() {
            const ip = await this.getIP();
            const url = new URL(document.location);
            const params = url.searchParams;

            Object.assign(this.form, {
                'ip': ip,
                'pid': params.get('pid') || null,
                'pid_sid': params.get('sid') || null
            });
        },
        async submit() {
            this.v$.$touch();

            if (this.v$.$error) return;
            else {
                const formData = new FormData();
                for (const key in this.form) {
                    formData.append(key, this.form[key]);
                }

                await fetch('./api/?action=registration', {
                    method: 'POST',
                    body: formData
                })
                .then( (response) =>
                {
                    this.$root.isPreloadGeneral = true;
                    return response.json();
                })
                .then( (result) =>
                {
                    if (result.error && result.error !== '')
                    {
                        let sErrorMessage = 'E-mail is already registered';

                        if (result.error === sErrorMessage)
                        {
                            this.$root.isPreloadGeneral = false;
                            alert(sErrorMessage);

                            return;
                        }
                    }
                    else if (result.location)
                    {
                        setTimeout(function()
                        {
                            document.location = result.location;
                        }, 500);
                    }
                })
                .catch( (e) =>
                {
                    console.error(e);
                    this.$root.isPreloadGeneral = false;
                });
            }
        },
        isDisabled () {
          return !!(
              !this.form.name ||
              !this.form.email ||
              !this.form.sign_agree ||
              this.errors['form.name'] ||
              this.errors['form.email'] ||
              this.v$.form.sign_agree.$error
          )
        }
    }
}
</script>

<style lang="scss">
    .form {

        &__error {
            margin: -4px 0 0 0;
            padding: 0 12px;
            font-size: 12px;
            text-align: right;
            color: $red;
        }
    }

    .registration {
        padding: 42px 28px 48px 28px;
        background-color: rgba(152, 88, 180, .9);
        border: 10px solid rgba(255, 255, 255, 0.15);
        box-shadow: 0px 8px 40px rgba(70, 43, 100, 0.3);
        border-radius: 20px;

        &__title {
            margin-bottom: 26px;
            text-align: center;
            font-family: 'Gilroy', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 34px;
            line-height: 1;
            color: #fff;
        }
    }
</style>