<template>
	<div>

		<Header />

		<div class="our-girls__tabs container">

			<picture class="our-girls__tabs__background">
				<source media="(min-width:1440px)" :srcset="require('@/assets/tab_1440.svg')">
				<source media="(min-width:1024px)" :srcset="require('@/assets/tab_1024.svg')">
				<source media="(min-width:768px)" :srcset="require('@/assets/tab_768.svg')">
				<source media="(min-width:320px)" :srcset="require('@/assets/tab_320.svg')">
				<img :src="require('@/assets/tab_1440.svg')" alt="#">
			</picture>

			<div class="our-girls__tab">
				<div class="our-girls__tab__head">
					<img :src="require('@/assets/cacke.svg')" alt="#">
					2008
				</div>
				We have been working since
			</div>

			<div class="our-girls__tab">
				<div class="our-girls__tab__head">
					<img :src="require('@/assets/girl.svg')" alt="#">
					7214
				</div>
				Single girls waiting on the site
				<img class="our-girls__tab__arrow" :src="require('@/assets/arrow-tab.svg')" alt="#">
			</div>

			<div class="our-girls__tab">
				<div class="our-girls__tab__head">
					<img :src="require('@/assets/cam.svg')" alt="#">
					856
				</div>
				Girls are online right now
			</div>

			<div class="our-girls__tab">
					<div class="our-girls__tab__head">
						<img :src="require('@/assets/heart.svg')" alt="#">
						2433
					</div>
					Singles found their soulmate
			</div>

		</div>

		<div class="wrap-content">

			<OurGirls />

			<h2 class="title title--h2">
				<img :src="require('@/assets/title.svg')" alt="ico">
				Our Girls
			</h2>

			<Info />

		</div>

		<Footer />

		<modal
			name="join-modal"
			width="373"
			height="auto"
		>
			<div class="popup">
				<button type="button" class="popup__close" @click="$modal.hide('join-modal')">
					<img src="@/assets/close.svg" alt="x" width="20">
				</button>
				<Registration />
			</div>
		</modal>

	</div>
</template>

<script>
import Header from '@/components/Header';
import OurGirls from '@/components/OurGirls';
import Info from '@/components/Info';
import Registration from '@/components/Registration';
import Footer from '@/components/Footer';

export default {
	name: 'Home',
	components: {
		Header,
		OurGirls,
		Info,
		Footer,
		Registration,
	},
	created() {
		this.sendPidAndSid();
	},
	methods: {
		async sendPidAndSid() {
			if (process.env.NODE_ENV === 'development') return;
			const oSearchParams = new URL(document.location).searchParams;
			oSearchParams.set('referer', document.referrer);

			try {
				const query = await fetch('/api/?action=send_partner_info', {
					method: 'POST',
					body: oSearchParams,
				});
				const { error } = await query.json();
				if (!error) {
					window.history.replaceState({}, document.title, window.location.pathname + '?pid=' + oSearchParams.get('pid') + '&sid=' + oSearchParams.get('sid'));
				} else {
					this.error = error;
				}
				console.log("Success send pid/sid");
			} catch(error) {
				console.log(error);
			}
		}
	}
}
</script>

<style lang="scss">

	.popup__close {
		background: none;
		padding: 0;
		border: none;
	}

	.wrap-content {
		background: url('~@/assets/bg-content.svg') no-repeat 100% 0 / 100% auto;
		padding-top: 120px;

		.title--h2 {
			margin-top: 0;
		}

		@include md {
			background-size: 1302px auto;
			padding-top: 80px;
		}

		@include sm {
			background-size: 1091px auto;
			padding-top: 80px;
		}

		@media (max-width: 620px) {
			background-size: 644px auto;
    }

		@include xs {
			padding-top: 40px;
		}

		@include xs-sm {
			background-position: 50% 0;
		}
	}

	.our-girls {

		&__tabs {
			z-index: 1;
			position: relative;
			display: flex;
			background-color: #fff;

			@include xs {
				flex-direction: column;
				padding: 40px 48px 0 48px;
			}

			&__background {
				z-index: -1;
				position: absolute;
				top: 0;
				left: 50%;
				width: 100vw;
				height: 100%;
				transform: translateX(-50%);

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}

		&__tab {
			font-weight: 700;
			color: #7166B5;

			@media (max-width: 1023px) {
				font-size: 16px;
				line-height: 20px;
			}

			@include xs {
				margin: 0 0 40px 0;
			}

			@include sm {
				width: calc(100% / 3);
				padding: 50px 20px 42px 20px;
			}

			@include md {
				width: calc(100% / 3);
				padding: 80px 20px 54px 20px;
			}

			@include lg {
				width: 25%;
				padding: 60px 50px 54px 50px;
			}

			&:nth-child(3) {

				.our-girls__tab__head {
					color: transparentize($pink, .11);
				}

				@media (min-width: 768px) {
						position: relative;

						&::before {
								content: '';
								z-index: -1;
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%);
								backdrop-filter: blur(16px);
						}
				}
			}

			&__head {
					display: flex;
					align-items: center;
					margin: 0 0 8px 0;
					font-family: 'Gilroy', sans-serif;
					line-height: 100%;
					color: #fff;

					@include xs {
							font-size: 50px;
					}

					@include sm {
							font-size: 50px;
					}

					@include md {
							font-size: 50px;
					}

					@include lg {
							font-size: 70px;
					}

					img {
							flex-shrink: 0;
							width: 32px;
							height: 32px;
							margin: 0 15px 0 0;
					}
			}

			&__arrow {
				@include xs {
						display: none;
				}

				@media (min-width: 768px) {
						position: absolute;
						top: 100%;
						left: 0;
						width: 100%;
				}
			}

			@media (max-width: 1439px) {

				&:nth-child(4) {
						display: none;
				}
			}
		}
	}


	.main {
		position: relative;

		&__background {
			z-index: -1;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				object-fit: contain;
				height: 100%;
				object-position: top center;
			}
		}
	}

	.popup {
		position: relative;
		width: 100%;
		max-width: 373px;
		margin: 0 auto;
		padding: 0 20px;

		&__close {
			width: 18px;
			height: auto;
			margin: 0 0 20px auto;
			display: block;
			transition: .3s ease-in-out;
			cursor: pointer;

			&:hover {
				opacity: 0.7;
			}
		}
	}

	.vm--modal {
		background: transparent !important;
		box-shadow: none !important;
	}

</style>
